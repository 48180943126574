.<template>
  <div class="virtual">
    <!-- <div class="background-img"></div> -->
    <h1 class="title">Virtual Screening</h1>
    <div class="virtual-div">
      <div class="sections">
        <section>
          <div class="step1">
            <div class="input">
              <h2>Select Target:</h2>
              <div class="model-input">
                <el-select v-model="selectedModel" filterable placeholder="Please Select Model" size="large"
                  class="model-select">
                  <el-option v-for="item in modelList" :key="item.name" :label="item.label"
                    :value="item.name_use_on_web" @click="onSelectModel(item.category, item.name)">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="select_data">
              <div class="mode" v-for="(models, mode, idx) in model" :key="mode">
                <div class="mode-bar">
                  <div class="mode-name">{{ mode }}</div>
                  <div class="show-all-btn" @click="onClickShowAll(idx)">
                    <p v-if="!isShowAll[idx]">view all</p>
                    <p v-else>Fold</p>
                  </div>
                </div>
                <div :class="['models', isShowAll[idx] ? 'show-all' : '']">
                  <div class="model-item" v-for="model in models" :key="model.name"
                    @click="onSelectModel(model.category, model.name)">
                    <img class="model-icon" src="../assets/model_icon.svg" alt="" />
                    <a :title="model.name_use_on_web">{{
                        model.name_use_on_web
                    }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="step2">
            <h2>Select a dataset:</h2>
            <div class="offer-datasets">
              <div class="radio-input">
                <input type="radio" name="select-dataset" id="offer-dataset-radio" @change="onRadioChange"
                  checked="isOfferDataset" />
                <label class="label" for="offer-dataset-radio">Select an available dataset</label>
              </div>
              <div class="offer-dataset">
                <div class="dataset-item" v-for="dataset in offerDatasets" :key="dataset.name">
                  <input type="radio" class="dataset-item" name="dataset-item" :value="dataset.name"
                    v-model="selectedOfferDataset" :id="dataset.name" :disabled="isUserDataset" />
                  <label :for="dataset.name">
                    {{ dataset.name }}
                    <span class="dataset-number">({{ dataset.number }})</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="user-dataset">
              <div class="radio-input">
                <input type="radio" name="select-dataset" id="user-dataset-radio" :checked="isUserDataset"
                  @change="onRadioChange" />
                <label class="label" for="user-dataset-radio">Upload a dataset</label>
              </div>
              <div class="upload-dataset">
                <button id="upload-btn" :disabled="isOfferDataset">
                  Upload CSV File
                  <input type="file" id="input-file" accept=".csv" @change="onUploadFile" :disabled="isOfferDataset" />
                </button>
                <p class="file-name">{{ fileName }}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="step3">
        <img src="../assets/singleprediction/photo1.png" alt="" />
        <button @click="onSubmit" id="submit-btn">Submit</button>
      </div>
    </div>
    <virtual-popper v-show="showPopper" :infos="popperInfo" @onSubmit="onSubmitPopper" @onCancel="onCancelPopper">
    </virtual-popper>
  </div>
</template>

<script>
import modelData from "../api/data/ishiner_dataset.json";
import offerDatasets from "../api/data/offer_dataset.json";
import { virtualApi } from "../api/index";
import popper from "../components/popper/popper.vue";
export default {
  data() {
    return {
      selectedMode: "",
      selectedModel: "",
      selectedOfferDataset: "",
      modelList: [],
      model: {
        "Cancer Cell Lines": [],
        "Anti-cancer Targets": [],
        Kinase: [],
        "NCI60 Cancer Cells": [],
        "Virus Cell Lines": [],
        "Virus Targets": [],
      },
      isShowAll: [0, 0, 0, 0, 0, 0],
      offerDatasets: [],
      isOfferDataset: true,
      isUserDataset: false,
      fileName: "no file",
      datasetFile: null,
      taskName: "",
      showPopper: false,
      popperInfo: {},
      serviceTime: this.$store.getters.getServiceSelectionTimes,
    };
  },
  components: {
    "virtual-popper": popper,
  },
  mounted() {
    // init model data
    for (let i = 0; i < modelData.length; i++) {
      this.modelList.push(modelData[i]);
      this.model[modelData[i].category_use_on_web].push(modelData[i]);
    }
    console.log(offerDatasets.length);
    for (let i = 0; i < offerDatasets.length; i++) {
      this.offerDatasets.push(offerDatasets[i]);
    }
    // this.offerDatasets.push({
    //   name: "example_database",
    //   number: 3000,
    // });
  },
  methods: {
    onClickShowAll(idx) {
      this.isShowAll[idx] = !this.isShowAll[idx];
    },
    onSelectModel(mode, modelName) {
      console.log(mode, modelName);
      this.selectedMode = mode;
      this.selectedModel = modelName;
    },
    onRadioChange() {
      this.isUserDataset = !this.isUserDataset;
      this.isOfferDataset = !this.isOfferDataset;
    },
    onUploadFile(e) {
      this.datasetFile = e.target.files[0];
      console.log(typeof this.datasetFile);
      this.fileName = this.datasetFile.name;
    },
    onSubmitPopper(taskName) {
      this.taskName = taskName;
      this.startSelect();
      this.showPopper = false;
    },
    onCancelPopper() {
      this.showPopper = false;
      console.log("oncancel");
    },
    onSubmit() {
      if (this.checkForm()) {
        this.popperInfo = {
          Mode: this.selectedMode,
          Model: this.selectedModel,
          "Available Time": this.serviceTime,
        };
        this.showPopper = true;
      }
    },
    checkForm() {
      //检查登陆
      if (!this.$store.getters.getToken) {
        this.$store.commit("alertMsg", {
          msg: "Please login first!",
          type: "error",
        });
        this.$router.push({
          path: "/account/sign-in",
          query: { redirect: this.$route.path },
        });
        return false;
      }
      if (this.selectedModel == "") {
        this.$store.commit("alertMsg", {
          msg: "No model is selected!",
          type: "error",
        });
        return false;
      }
      if (
        (this.isOfferDataset && this.selectedOfferDataset == "") ||
        (this.isUserDataset && this.fileName == "no file")
      ) {
        this.$store.commit("alertMsg", {
          msg: "No selected or uploaded dataset",
          type: "error",
        });
        return false;
      }
      return true;
    },
    startSelect() {
      if (this.isOfferDataset) {
        this.fetchWithoutFile();
      } else if (this.isUserDataset) {
        this.fetchWithFile();
      } else {
        this.$store.commit("alertMsg", {
          msg: "No selected or uploaded dataset",
          type: "error",
        });
      }
    },
    fetchWithoutFile() {
      virtualApi
        .select(this.selectedMode, {
          database_name: this.selectedOfferDataset,
          title: this.taskName,
          model_name: this.selectedModel,
        })
        .then(this.handleFetchResult)
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
    fetchWithFile() {
      const formData = new FormData();
      formData.append("file", this.datasetFile);
      formData.append("title", this.taskName);
      formData.append("model_name", this.selectedModel);
      virtualApi
        .selectWithFile(this.selectedMode, formData)
        .then(this.handleFetchResult)
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
    handleFetchResult(res) {
      if (res.code == 200) {
        this.$store.commit("alertMsg", {
          msg: "Submit sucessfully",
          type: "ok",
        });
        // 重定向到历史结果页
        setTimeout(() => {
          this.$router.push({
            path: "/user/virtual_history",
          });
        }, 1200);
      } else if (res.code == 401) {
        this.$store.commit("alertMsg", {
          msg: "Please login first!",
          type: "error",
        });
        this.$router.push({
          path: "/account/sign-in",
          query: { redirect: this.$route.path },
        });
      } else {
        this.$store.commit("alertMsg", {
          msg: res.msg,
          type: "error",
        });
      }
    },
  },
  computed: {
    // filterModelList() {
    //   if (!this.selectedModel || this.selectedModel == "")
    //     return this.modelList;
    //   else {
    //     let pattern = this.selectedModel.trim().toLowerCase();
    //     return this.modelList.filter((item) => {
    //       item.match(pattern);
    //     });
    //   }
    // },
  },
};
</script>

<style scoped>
/* .background-img {
  z-index: 1;
  background-image: url(../assets/singleprediction/background.png);
  background-size: 60vmax;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
} */
h2 {
  font-size: 20pt;
}

.virtual {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  color: white;
}

.virtual-div {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
}

.title {
  font-size: 30pt;
  margin: 20px 0;
  padding: 0 10px;
  text-align: center;
}

.sections {
  display: flex;
  width: 90%;
}

.input {
  width: fit-content;
  /* background: red; */
  display: flex;
  flex-direction: column;
}

.input_tip {
  margin: 5px 0;
}

.step1_lable {
  font-size: 20pt;
}

#step1_input {
  box-sizing: border-box;
  width: 300px;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  margin: 5px;
}

.step_tip {
  display: flex;
  align-items: center;
}

section {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin: 50px 0;
}

.mode {
  width: fit-content;
  /* background-color: var(--color2-dark);  */
  box-sizing: border-box;
  padding: 0 0px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.mode-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* background: red; */
}

.mode-name {
  font-size: 16pt;
  font-weight: bolder;
  margin: 5px 0;
}

.show-all-btn {
  width: fit-content;
  align-self: end;
  padding: 0 10px;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}

.show-all-btn:hover {
  color: var(--color2-dark);
}

.model-select {
  margin-top: 10px;
}

.models {
  width: fit-content;
  height: 40px;
  display: grid;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 10px;
  grid-template-columns: repeat(4, 150px);
  gap: 10px;
}

.models.show-all {
  /* overflow-y: scroll; */
  height: fit-content;
}

.model-item {
  box-sizing: border-box;
  height: 30px;
  background-color: var(--color2-dark);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  padding: 5px;
  text-align: center;
  color: var(--color1-dark);
  display: flex;
}

.model-icon {
  /* width: 10px; */
  padding: 0 2px;
  height: 100%;
}

.model-item a {
  flex: 1;
  text-align: center;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.model-item:hover {
  color: white;
  /* white-space: nowrap;
  min-width: fit-content;
  overflow: visible;*/
}

.step2 {
  /* ba0ckground: pink; */
  height: fit-content;
  width: 80%;
}

.step3 {
  display: flex;
  width: 100%;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
}

.step3 img {
  width: 90%;
}

#submit-btn {
  height: 40px;
  margin: 30px;
  border-radius: 15px;
  font-size: 20pt;
  padding: 6px 0;
  width: 350px;
  border: none;
  outline: none;
  cursor: pointer;
  background: linear-gradient(to right,
      var(--color2-dark),
      var(--color2-light));
}

.offer-datasets {
  margin: 20px 0;
}

.offer-dataset {
  display: grid;
  grid-template-columns: repeat(2, 300px);
}

.dataset-item {
  margin: 10px;
}

.dataset-number {
  color: grey;
}

.upload-dataset {
  margin: 20px;
  display: flex;
  align-items: center;
}

#upload-btn {
  position: relative;
  height: 40px;
  padding: 0 10px;
  padding-left: 10px;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 14pt;
  /* width: 100px; */
}

.label {
  margin-left: 10px;
  font-size: 20px;
}

.file-name {
  margin: 0 10px;
}

#input-file {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  bottom: 0;
}

@media (max-width: 1000px) {
  .virtual {
    flex-direction: column;
  }
}
</style>